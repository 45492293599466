// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem($menu-bar);
	overflow-x: hidden;
	
	@include breakpoint(medium) {
		padding-top: 0;
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	padding: rem(10px) rem($base-gap);
	width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../images/layout/mobile-bg.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 48rem;

    body.iexplore & {
    	display: block;

    	> .row {
    		margin-top: rem(200px);
    	}
    }

	body:not(.index) & {
	
	    height: 22rem;
	    min-height: 20rem;

		@include breakpoint(giant) {
		    height: 25rem;
		    min-height: 20rem;
		}
	}

	.branding {
		display: block;
		float: right;

		@include breakpoint(medium) {
			float: left;
		}
	}


	@include breakpoint(large) {
		min-height: 43rem;
	}

	@include breakpoint(large) {

		height: 100vh;
        background-image: url(../images/layout/header-bg.jpg);
        background-position: center;
        min-height: 48rem;
	}
	@include breakpoint(huge) {
		min-height: 45rem;
	}

	.logo {
	    display: flex;
	    justify-content: center;
	    margin-bottom: 30px;
	    margin-top: 10px;

		@include breakpoint(tiny) {
		    margin-bottom: 75px;
		    margin-top: -74px;
    	}
		@include breakpoint(small) {
		    margin-bottom: 120px;
		    margin-top: -20px;
    	}
		@include breakpoint(medium) {
		    margin-bottom: 100px;
		    margin-top: 30px;
    	}
		@include breakpoint(large) {
			margin-bottom: 160px;
	    	margin-top: 90px;
    	}

	  	body:not(.index) & {
		    margin-bottom: 0;
		    margin-top: 0;

			@include breakpoint(large) {
			    margin-top: 56px;
	    	}
	 	}
	}
	.claim {
		text-align: center;

		.usp {

			body:not(.index) & {
			 display: none;
			}

			color:$secondary;
			font-weight:bold;
		    font-size: 30px;
		    line-height: 36px;

			span {
				display:block;
				color:$light;
				font-weight: 100;
			    margin-top: 1rem;
		        line-height: 28px;
		        font-size: 16px;

		        br {
		        	display: none;

		        	@include breakpoint(large) { display: inline-block; }
		        }
			}


			@include breakpoint(tiny) {
			    font-size: 36px;
			    line-height: 42px;

				span {
					font-size: 20px;
					line-height: 34px;
					margin-top: 2rem;
				}
			}
			@include breakpoint(small) {
				font-size:42px;

				span {
				    font-size: 20px;
    				line-height: 30px;
				}
			}
			@include breakpoint(large) {
				font-size:42px;

				span {
					font-size: 24px;
					line-height: 34px;
				}
			}
		}

		.produkt, .termin {

			@include breakpoint(small, max) {
				display: flex;
				justify-content: center;
			}
		}

		.produkt {
			@include breakpoint(small) {
			    display: flex;
    			justify-content: flex-end;
			}
		}

		.claim-btn {
		    padding: .7rem 2rem;
	        margin-bottom: 1.5rem;
		}

		.buttons {
			body:not(.index) & {
			 display: none;
			}
		}
	}
}

.teaser {
    background: $dark;
    height: 28rem;
    margin-top: 75px;
    margin-bottom: 180px;

    @include breakpoint(large) {
    	    margin-top: 150px;
    		margin-bottom: 250px;
    }

    .slider {
		height: 100%;

		.slidebox  {
			height: 100%;
			background: $dark;
			position: relative;
			text-decoration: none;
			transition: all 1s ease;
		    background-size: cover !important;

			&:before {
			    position: absolute;
			    content: "";
			    bottom: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    z-index: 0;
			    display: block;
			    background-color: rgba(0,0,0,0.7);
			    transition: all 1s ease;
			}

			&.accessoires {
				background: image-url('teaser/accessoires.jpg') no-repeat;
			}
			&.armaturen {
				background: image-url('teaser/armaturen.jpg') no-repeat;
			}
			&.badewannen {
				background: image-url('teaser/badewannen.jpg') no-repeat;
			}
			&.badmoebel {
				background: image-url('teaser/badmoebel.jpg') no-repeat;
			}
			&.dusche {
				background: image-url('teaser/dusche.jpg') no-repeat;
			}
			&.fliesen {
				background: image-url('teaser/fliesen.jpg') no-repeat;
			}
			&.heizungen {
				background: image-url('teaser/heizung.jpg') no-repeat;
			}
			&.maler {
				background: image-url('teaser/maler.jpg') no-repeat;
			}
			&.natur {
				background: image-url('teaser/natursteine.jpg') no-repeat;
			}
			&.tische {
				background: image-url('teaser/waschtische.jpg') no-repeat;
			}
			&.wc {
				background: image-url('teaser/wc.jpg') no-repeat;
			}
			&.wellness {
				background: image-url('teaser/wellness.jpg') no-repeat;
			}


			span {
				display: block;
    			position: relative;
    			color: $light;
    			transition: all 1s;

    			&.navipunkt {
				    text-decoration: none;
				    bottom: 70px;
				    position: absolute;
				    transition: opacity .5s;
				    transform: rotate(-90deg);
    			}


    			i {
				    margin-left: 10px;
				    color: $secondary;
    			}
			}
			.hover-box {
    				opacity:0;
			        bottom: 42px;
    				position: absolute;
				    text-align: center;
				    left: 0;
				    margin: 0 95px;
				    transition: opacity .5s;

    			.btn, .extra-btn {
				    display: inline-block;
			        margin-top: 2rem;
    			}

    			.headline {
				    font-size: 20px;
				    font-weight: bold;
				    margin-bottom: 1rem;
    			}
			}

			&:hover {

				&:before {
				    background-color: $dark;
				}
				span {


	    			&.navipunkt{
	    				opacity: 0;
	    			}

				}
    			.hover-box {
	    				opacity:1;
    			}
			}

			body.nojs & {
				
				&:last-child {
    				margin-bottom: 125px;

    				@include breakpoint(large) {
					    margin-bottom: 250px;
    				}
				}
				
			}
		}

		

    }
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
    padding: 75px 0;

    @include breakpoint(large) {
    	padding: 150px 0;
    }

    a.border-right {
    	display: inline-block;
    	
    }

	ul, dl {
		@extend .styled-list;


		li {
			padding: .6rem 0 .6rem 3.25rem;
    	    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);

	    	&:before {
			    text-indent: -2rem;
			    font-size: 1rem;
			    font-family: 'FontAwesome';
			    font-weight: 900;
			    content: "\f00c";
			    position: relative;
			    display: inline-block;
			    color: $secondary;
	    	}
		}
	}


	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}




	.leistungspunkte {

		.col {

			&.zuverlaessig {
				h4 {
					&:before {
						background-image: image-url('unternehmen/zuverlaessig.png');
					}
				}
			}
			&.sauber {
				h4 {
					&:before {
						background-image: image-url('unternehmen/Sauber.png');
					}
				}
			}
			&.termingerecht {
				h4 {
					&:before {
						background-image: image-url('unternehmen/Termingerecht.png');
					}
				}
			}
			&.erstklassig {
				h4 {
					&:before {
						background-image: image-url('unternehmen/Erstklassig.png');
					}
				}
			}
			&.creativebad {
				h4 {
					&:before {
						background-image: image-url('unternehmen/creativbad-owl.png');
					}
				}
			}
			&.cleobadtra {
				h4 {
					&:before {
						background-image: image-url('leistungen/cleobadtra.png');
					}
				}
			}

			h4 {

				font-size: 16px;

				&:before {
				    background-position: center;
				    background-repeat: no-repeat;
				    background-size: contain;
				    content: '';
				    display: block;
			        font-size: 60px;
				    height: 1em;
				    width: 100%;
				    margin-bottom: 1rem;
				}
			}

			text-align: center;
			font-size: 16px;
		    margin-bottom: 3.5rem;

		    p {
		    	font-size: 14px;
		    	@include breakpoint(tiny) {
					min-height: 96px;
		    	}
    	      
		    }

			.btn {
			    display: table;
			    align-self: center;
			    margin-left: auto;
			    margin-right: auto;
			    font-size: 16px;
		        width: 100%;
			}
		}

	}

	img.ueber-uns {
		margin-bottom: rem(15px);

		@include breakpoint(small) {
			margin-bottom: rem(18px);
		}
	}


}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	background: $dark;
    padding: 95px 0;

    @include breakpoint(large) {
    	padding: 190px 0;
    }

    .data {
    	display: none;
		@include breakpoint(large) {
	    	display: block;
		}

    }

	@include breakpoint(large) {
	    padding: 180px 0
	}

	.h3 {
		font-weight: bold;
		text-align: center;
		font-size: 30px;
	    margin-bottom: 100px;
	    color:$light;
	    line-height: 36px;
	}

	@include breakpoint(large, max) {
		h4 {
		    font-weight: bold;
		    text-align: center;
		    font-size: 30px;
		    color: $light;
		}
	}

	.extra-line {
	    margin-bottom: 5rem;
		margin-top: 2rem;
	}
	.small-line {
		display: none;

		@include breakpoint(large) {
			display: flex;
		    margin-bottom: 3rem;
		    margin-top: 1rem;
	        background: rgba(255, 255, 255, 0.4);
		}
	}

	.leistungen {

		.col {

			&.musterbaeder {
				h4 {
					&:before {
						background-image: image-url('leistungen/badservice.png');
					}
				}
			}
			&.produkte {
				h4 {
					&:before {
						background-image: image-url('leistungen/badprodukte.png');
					}
				}
			}
			&.unternehmen {
				h4 {
					&:before {
						background-image: image-url('leistungen/luxusbad-bielefeld.png');
					}
				}
			}
			&.partner {
				h4 {
					&:before {
						background-image: image-url('leistungen/partner.png');
					}
				}
			}
			&.creativebad {
				h4 {
					&:before {
						background-image: image-url('leistungen/creativbad-owl.png');
					}
				}
			}
			&.cleobadtra {
				h4 {
					&:before {
						background-image: image-url('leistungen/cleobadtra.png');
					}
				}
			}

			h4 {

				font-size: 16px;

				&:before {
				    background-position: center;
				    background-repeat: no-repeat;
				    background-size: contain;
				    content: '';
				    display: block;
			        font-size: 60px;
				    height: 1em;
				    width: 100%;
				    margin-bottom: 1rem;
				}
			}

			text-align: center;
			font-size: 16px;
		    margin-bottom: 3.5rem;

		    p {
		    	font-size: 14px;
		    	@include breakpoint(tiny) {
					min-height: 96px;
		    	}
    	      
		    }

			.btn {
			    display: table;
			    align-self: center;
			    margin-left: auto;
			    margin-right: auto;
			    font-size: 16px;
		        width: 100%;
			}
		}

	}

	.contactdata {
		ul {
			font-size: 14px;
			margin-bottom: 1.5rem;
		}
		span {
		    font-size: 14px;
		}
		dl {
			margin-bottom: 0;
			font-size: 14px;
			dt {
				font-weight: bold;
			}

			&.opentimes {
				dt, dd {
				    display: block;
				    vertical-align: top;
				    float: left;
				}
				dt {
				    padding-right: .5rem;
				    width: 40%;
				    clear: left;
				    font-weight: 400;
				}
				dd {
				    width: 60%;
				}
			}
		}
	}

	.socials {

		display: none; 

		@include breakpoint(large) {
		    display: flex;
		    justify-content: flex-end;
		}

		a, .clock-icon {
		    display: inline-block;
		    vertical-align: middle;
	        width: 2.5rem;
		    transition: all 1s;
		    font-size: 24px;
		    color: white;
		    opacity: 1;
		    transition: all 1s;


		    img {
			    display: block;
			    height: 1.4rem;
			    width: auto;
			    transition: 400ms;
			    margin-top: 2px;
		    }


		}


    	.clock-icon, .tapHover {
		    margin-right: 0.7rem;
		    font-size: 22px;
    		&:hover {

				.opentimes-box {
				    height: 23rem;
    				opacity: 1;
		    		visibility: visible;
				}

    		}
    	}

		.opentimes-box {
		    background: #2a2a2a;
		    position: absolute;
		    top: 44px;
		    right: 0;
		    padding: 2rem;
		    z-index: 3;
		    border-top: 1px solid $light;
		    border-bottom: 1px solid $light;
		    height: 0;
		    transition: all 1s;
		    opacity: 0;
		    visibility: hidden;
		    width: 375px;
		    font-size: 18px;
		    color:$light;

		    .headline {
		    	font-weight: bold;
		    }

		    dl {
		    	dt {
		    		font-weight: 400;
		    	}
		    }
		}
	}

	.hinweis {
		display: none;
		color: red;

			body.nojs & {
				display: block;
				
			}
	}

	.lieferranten-slider {
	    margin-top: 3rem;
    	margin-bottom: 3rem;


			body.nojs & {
				div {
				display: none;
				&:last-child {

				}
				}
				
			}

    	.slick-prev {
		    left: -15px;
	        top: 60%;

    	}
    	.slick-next {
		    right: -15px;
	        top: 60%;

    	}
		.slick-prev, .slick-next  {
			 &:hover {
			 	border:none;
			 	color: $secondary;
			 	&:before {
			 		color: $secondary;
			 	}
			 }
		}

		.slick-slide {

			    margin: 0 1rem;

		}
	}
}

.infobox {
	// background-color: aquamarine;
	width: 100%;
	height: 4rem;
	box-sizing: content-box;
	overflow: hidden;
	// padding-left: 100%;
	padding-top: 1rem; 
	
	.ticker{
		// background-color: blueviolet;
		display: inline-block;
		height: 4rem;
		width: max-content;
		margin: auto;
		line-height: 4rem;  
		white-space: nowrap;
		box-sizing: content-box;
		// padding-right: 100%; 
		
		$duration: 30s;
		-webkit-animation-iteration-count: infinite; 
		animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-name: ticker;
		   animation-name: ticker;
		-webkit-animation-duration: $duration;
		animation-duration: $duration;
		
		& .ticker_item {

			// background-color: cadetblue;
			display: inline-block;
			text-align: center;
			padding: 0 2rem;
			font-size: 1rem;
	  
		  }
	}
	
	@keyframes ticker
	{
		from
		{
			transform: translate3d(100%, 0%, 0px)
		}
		to
		{
			transform: translate3d(-100%, 0%, 0px);
		}
	}
}

