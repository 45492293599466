// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	transition: all 1s;
	// Off Canvas Styles
	//////////////////////////////
	
	@include breakpoint(large, max) {
		background: $extra;
		left: 0;
		overflow-y: auto;
		position: fixed;
		top: rem($menu-bar);
		transform: translateX(-100%);
		transition: .6s;
		width: map-get($breakpoints, tiny) * .8;
		z-index: 895;
	    bottom: 0;
	    max-width: 100%;

		.col {
			padding-left: 0;
			padding-right: 0;
		}

	    nav {
    	    position: relative;
		    width: 100%;
		    background: $extra;
	    }

		.navi-main {


		    > .hasSub {
		    	 span, a {
		    		&:after {
				        content: "\f107";
					    font-family: 'FontAwesome';
					    font-weight: 900;
					    margin-left: 0.5rem;
		    		}
		    	}
		    }

			li {


				position: relative;
			    display: block;
			    align-self: flex-start;



				&.active, &:hover {

					span {
						color:$dark;
					    font-weight: bold;
					}
					a {
						font-weight: bold;

					    &:before {
						    opacity: 1;
						    visibility: visible;
					    }
					}

				}

				a, span {
				    display: block;
				    color: $light;
				    line-height: 1em;
				    text-decoration: none;
				    padding: .9375rem 3.125rem .9375rem 1.125rem;
				    border-bottom: .0625rem solid rgba(255,255,255,0.25);


				}

				&:last-child {
			        border-bottom: none;
				}

				span, a {
					&:before {
				     	position: absolute;
					    content: '\f061';
					    font-family: "FontAwesome";
					    font-size: .75rem;
					    font-weight: 900;
					    text-transform: uppercase;
					    top: calc(50% + .0625rem);
					    right: 1.125rem;
					    transform: translateY(-50%);
					    opacity: 0;
					    visibility: hidden;
					}
				}
			}

			.facebook {
			    background: #4267b2;
			}
			.youtube {
			    background: #ff0000;
			}
			.auf-google-bewerten {
				background: #eb4134;
			}
			
			a.active {
  				color: $secondary;
  			}
			.hasSub {

				.sub {


					li {

					}

					a {
						font-weight: normal;
						&:before {
						    opacity: 0;
					    	visibility: hidden;
						}

						&:after {
							display: none;
						}

					}
				    a {
					    display: block;
					    color: $dark;
					    background-color: $light;
					    text-decoration: none;
					    padding: .5625rem 3.125rem .5625rem 1.125rem;
					    border-left: .625rem solid $dark;
					    border-bottom: .0625rem solid $dark;

				        &:hover,
				        &:focus,
				        &.active {
							font-weight: bold;


							&:before {
						    opacity: 1;
						    visibility: visible;
							}
				        }
				    }
				}
			}

		}
	}

	@include breakpoint(large) {

	    display: block;
	    position: absolute;
	    width: 100%;
	    z-index: 10;
        top: 45px;
        left:0;

		.navi-main {
			    display: flex;
			    flex-basis: 100%;
			    flex-wrap: wrap;
			    justify-content: space-between;
			li {
				
				&:hover {

					a {
						color: $secondary;
						position: relative;


						&:before {
						    width: 100%;
						}
					}
				}
				
				&.active {

					a, span {
						color: $secondary;
						position: relative;


						&:before {
						    content: "";
						    height: 1px;
						    width: 100%;
						    position: absolute;
						    left: 0;
						    bottom: -15px;
						    background: $secondary;

						}
					}
				}

				a {
					color: $light;
					text-decoration: none;
					position: relative;
					transition: all 1s;

					&:before {
					    content: "";
					    height: 1px;
					    width: 0%;
					    position: absolute;
					    left: 0;
					    bottom: -15px;
					    background: $secondary;
					    transition: all 1s;

					}
				    @include breakpoint(huge) {
					    font-weight: bold;
				    }
				}

                &:hover > .sub, &.hovering > .sub {
                    opacity:1;
                    top:100%;
                    visibility:visible;
                }
			}

			.kontakt, .impressum, .datenschutzerklaerung, .youtube, .facebook, .auf-google-bewerten {
				display: none;
			}

			> .hasSub {
			    position: relative;
				
		    		&:hover {
		    			a {
				    		&:before {
				    			display: none;
				    		}
		    			}

		    		}


				> span, a {

					color:$light;

					&.active {
						color: $secondary;
					}

				    @include breakpoint(huge) {
					    font-weight: bold;
				    }
		    		&:after {
				        content: "\f107";
					    font-family: 'FontAwesome';
					    font-weight: 900;
					    margin-left: 0.5rem;
		    		}

				}
				.sub {
				    background-color:$secondary;
				    box-shadow:0 5px 10px rgba(black, .15);
				    height:auto;
				    left:50%;
				    margin:auto;
				    min-width:100%;
				    opacity:0;
				    padding:0;
				    position:absolute;
				    text-align:left;
				    top:120%;
				    transform:translate3d(-50%,0,0);
				    transition:300ms;
				    visibility:hidden;
				    z-index:10;

				    li + li {
				        border-top:1px solid rgba($light, .25);
				    }

				    a {
				        color:$light;
				        display:block;
				        font-size:rem(14px);
				        padding:rem($base-gap / 2) rem($base-gap);
				        text-decoration:none;
				        white-space:nowrap;

				        &:hover,
				        &:focus,
				        &.active {
				            background-color:$dark;
				            color:$light;
				            text-decoration:none;
				        }

				        &:after {
				        	display: none;
				        }


				    }
				}
			}
		}
	}
}
.navScroll {
	@include breakpoint(large) {
	    position: fixed;
	    background: black;
	    top: 0;
	    padding: 1rem 0;

		.navi-main {
			li {
				a {
					&:before {
				        bottom: -17px !important;
					}
				}
			}
		}
	}
}



// Mobile Menu Bar
//////////////////////////////

.mobile-bar {
	align-items: center;
	background: $dark;
	display: flex;
	height: rem($menu-bar);
	justify-content: space-between;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 894;
	color: $light;
    border-bottom: 1px solid;


	.info {
		i {
			transform: rotate(0deg) !important;
		}
	}
	li {
		display: flex;
		flex-grow: 1;
		padding: 0 rem($base-gap);

		&:first-child {

			i {

				transform: rotate(110deg);
			}
		}

		&:last-child {
            justify-content: flex-end;
        }

        label[for="navi-toggled"] {
		    flex-direction: row-reverse;
		    position: relative;
		    height: 2.8125rem;
		    width: 2.8125rem;
		    top: 0;
		    left: 0;
		    z-index: 990;
    		display: block;
			font-size: rem(18px);



				i {
					position: absolute;
					display: block;
					width: calc(100% - #{rem($base-gap)});
					height: rem(2px);
					left: rem($base-gap/2);
					background-color: $light;
					transition: all .15s ease-in-out;

					&:nth-of-type(1) {
						top: rem(15px);
					}

					&:nth-of-type(2) {
						top: rem(22px);
						transition-delay: .15s;
					}

					&:nth-of-type(3) {
						top: rem(29px);
					}
				}

			&:after {
				background: rgba($dark,0.8);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: fixed;
				top: rem($menu-bar);
				transition: opacity .6s, visibility .6s;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}
		}

		a, .phone {
		    position: relative;
		    width: 30px;
		    height: 30px;
		    border-radius: 100%;
		    border: 1.5px solid #ffffff;
		    color: #fff;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    text-decoration: none;
		    transition: border-color .5s;
		    font-size: 12px;
	        margin-right: 0.5rem;
		}

		.info {

		}
	}

	@include breakpoint(large) {
		display: none;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			


			&:after {
				opacity: 1;
				pointer-events: auto;
				visibility: visible;
			}

			i {

				&:nth-of-type(1) {
					top: rem(22px);
					transform: rotate(45deg);
					transition-delay: .15s;
				}

				&:nth-of-type(2) {
					left: rem(45px);
					opacity: 0;
					transition-delay: 0s;
				}

				&:nth-of-type(3) {
					top: rem(22px);
					transform: rotate(-45deg);
					transition-delay: .15s;
				}
			}
		}

		~ * .page-navi, ~ .page-navi {
			transform: translateX(0);
		}
	}
}

.navi-add {

	display: none;

	@include breakpoint(large) {
	    display: flex;
	    flex-basis: 100%;
	    flex-wrap: wrap;
	    justify-content: space-between;
	}
	li {
		
		a {
			color: $light;
			text-decoration: none;
		}
		&:hover {

			a {
				color: $secondary;

			}
		}

		&.active {

			a {
				color: $secondary;



			}
		}
	}
	.produkte, .leistungen, .cleobadtra, .creativbad-owl, .startseite,  .ueber-uns, .partner {
		display: none;
	}
	@include breakpoint(giant) {
		.ueber-uns, .partner {
			display: inline-block;
		}
	}
	@include breakpoint(huge) {
		.startseite {
			display: inline-block;
		}
	}


}