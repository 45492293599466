/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slide-options {
    background: $dark;
    padding: 26px 0;
    display: flex;
    justify-content: center;

    body.nojs & {

        display: none;

    }
}




.slick-prev, .slick-next {
    width: rem(38px);
    height: rem(38px);
    border: rem(2px) solid $dark;
    outline: none;
    top: 110%;
    transform: translateY(-50%);
    background: transparent;
    color: transparent;
    font-size: 0;
        position: absolute;

    &:hover {
        outline: none;
        border: rem(2px) solid $light;
        background: transparent;

        &:before {
            color: $light;
        }
    }

    &:before {
        color:$light;
        font-size: rem(20px);
        transition: all .6s ease;
    }
}
.slick-arrow {
    z-index: 2;
    background: none;
    border: none;
    cursor: pointer;
}
.slick-prev {
            left: rem(10px);
            z-index: 2;

            &:before {
                @extend .fa-solid;
                @extend .fa-angle-left;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                color: $light;
            }
            @include breakpoint(tiny) {
                left: 5.5rem;
            }
            @include breakpoint(medium) {
                left: 14.5rem;
            }
            @include breakpoint(giant) {
                left: 22.5rem;
            }

            @include breakpoint(huge) {
                left: 34.5rem;;
            }
        }

        .slick-next {
            right:rem(10px);
            left: inherit;
            z-index: 2;

            &:before {
                @extend .fa-solid;
                @extend .fa-angle-right;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                color: $light;
            }
            @include breakpoint(tiny) {
                right: 5.5rem;
            }
            @include breakpoint(medium) {
                right: 14.5rem;
            }
            @include breakpoint(giant) {
                right: 22.5rem;
            }

            @include breakpoint(huge) {
                right: 34.5rem;
            }
        }